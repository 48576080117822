<template>
  <div class="advantage">
    <div class="image-wrapper">
      <img :src="image" class="image" alt="image">
    </div>

    <p class="title">
      {{ title }}
    </p>

    <p class="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppAdvantage',
  props: {
    image: String,
    title: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.advantage {
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(209, 212, 224, 0.25), 0 0 60px #E4EAF1;
  border-radius: 15px;
  padding: 20px;
  .image-wrapper {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .title {
    font-weight: 500;
    font-size: 21px;
    margin-top: 40px;
  }
  .text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>

import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

export default function useOnec () {
  /* DATA */
  const extraData = ref({
    title: 'Калькулятор услуги Аренда 1С'
  })
  const items = ref([])
  const selected = ref([])

  /* METHODS */
  const fetchItems = async () => {
    try {
      const res = await api.services.fetchOnec()
      const list = res.data?.list ?? []
      items.value = list.map(item => ({
        value: item.id,
        label: item.label,
        price: +item.price
      }))
    } catch (e) {
      console.log(e)
    }
  }

  /* COMPUTED */

  const total = computed(() => {
    if (selected.value.length === 0) { return 0 }

    return items.value.reduce((acc, i) => {
      if (selected.value.includes(i.value)) {
        return acc + i.price
      }

      return acc
    }, 0)
  })

  const receipt = computed(() => {
    if (selected.value.length === 0) { return [] }

    const arr = selected.value.map(id => {
      const curItem = items.value.find(i => i.value === id)
      return {
        key: curItem.label
      }
    })

    return [
      {
        title: extraData.value.title,
        options: arr
      }
    ]
  })

  onMounted(() => {
    fetchItems()
  })

  return {
    extraData,
    receipt,
    total,
    items,
    selected
  }
}

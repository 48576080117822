import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

export default function useAdditional (url) {
  if (!url) {
    throw new Error('URL не передан!!')
  }

  /* DATA */
  const extraData = ref({
    title: 'Дополнительные услуги'
  })
  const selected = ref([])
  const items = ref([])

  /* COMPUTED */
  const total = computed(() => {
    if (selected.value.length === 0) { return 0 }

    return items.value.reduce((acc, i) => {
      if (selected.value.includes(i.value)) {
        return acc + i.price
      }

      return acc
    }, 0)
  })

  const receipt = computed(() => {
    if (selected.value.length === 0) { return [] }

    const arr = selected.value.map(id => {
      const curItem = items.value.find(i => i.value === id)
      return {
        key: curItem.label
      }
    })

    return [
      {
        title: extraData.value.title,
        options: arr
      }
    ]
  })

  /* METHODS */
  const fetchAdditional = async (url) => {
    const res = await api.services.additional(url)
    const list = res.data?.list ?? []

    items.value = list.map(item => ({
      value: item.id,
      label: item.label,
      price: +item.price
    }))
  }

  onMounted(async () => {
    await fetchAdditional(url)
  })

  return {
    total,
    receipt,
    selected,
    items,
    extraData
  }
}

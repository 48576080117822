<template>
  <main class="one-c">
    <AppPreview
      title="1C в облаке"
      text="Удалённую работу с 1С можно организовать безопасно и эффективно - если доверить обслуживание надежному провайдеру."
    >
      <img src="/images/1ccloud.png" alt="preview">
    </AppPreview>

    <section class="slogan">
      <div class="container">
        <h2>Инфраструктура будущего - в облаке</h2>
        <p class="slogan__text">В эру победившего «клауда» вопрос перехода от информационных систем on-premise к подлинно облачной модели становится определяющим. Поэтому Вам будут нужны проверенные и безопасные сервисы для решения конкретных задач, созданные заказчиками и партнерами.</p>
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <h2>Преимущества выбора Cloud bit</h2>
        <div class="advantages__list">
          <AppAdvantage
            v-for="(advantage, index) of $store.getters.advantages"
            :key="index"
            :image="advantage.image"
            :title="advantage.title"
            :text="advantage.text"
            class="advantages-item"
          />
        </div>
      </div>
    </section>

    <section class="calc">
      <div class="container">
        <h2>{{ onecExtraData.title }}</h2>
        <p class="calc__subtitle">Доступные сервисы и конфигурации</p>
        <div class="calc-items">
          <div
            v-for="item of onecItems"
            :key="item.value"
          >
            <BaseCheckbox
              v-model="onecSelected"
              :value="item.value"
              :label="item.label"
              class="calc-items__item"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="additional">
      <div class="container">
        <h2>{{ additionalExtraData.title }}</h2>

        <div class="additional-checkboxes">
          <div
            v-for="item of additionalItems"
            :key="item.value"
          >
            <BaseCheckbox
              v-model="additionalSelected"
              :value="item.value"
              :label="item.label"
              class="additional-checkboxes__item"
            />
          </div>
        </div>
      </div>
    </section>

    <section
      class="container"
      style="margin-top: 60px;"
    >
      <PanelTotal
        :price="globalTotal"
        @open="$refs.requestModal.open()"
      />
    </section>

    <ModalRequest
      :total="globalTotal"
      ref="requestModal"
      @submit="onSubmit"
    >
      <AppReceipt
        :receipt="globalReceipt"
      />
    </ModalRequest>

    <ModalThank ref="modalThank" />
  </main>
</template>

<script>
import useOnec from '@/composables/useOnec'
import useAdditional from '@/composables/useAdditional'

import BaseCheckbox from '@/components/Base/BaseCheckbox'
import AppPreview from '@/components/AppPreview'
import AppAdvantage from '@/components/AppAdvantage'
import PanelTotal from '@/components/PanelTotal'
import ModalRequest from '@/components/ModalTemplates/ModalRequest'
import ModalThank from '@/components/ModalTemplates/ModalThank'
import AppReceipt from '@/components/AppReceipt'

export default {
  name: 'PageOneC',
  components: {
    BaseCheckbox,
    AppPreview,
    AppAdvantage,
    PanelTotal,
    ModalRequest,
    ModalThank,
    AppReceipt
  },
  setup () {
    const {
      receipt: onecReceipt,
      extraData: onecExtraData,
      total: onecTotal,
      items: onecItems,
      selected: onecSelected
    } = useOnec()

    const {
      receipt: additionalReceipt,
      total: additionalTotal,
      selected: additionalSelected,
      items: additionalItems,
      extraData: additionalExtraData
    } = useAdditional('/service/onec/additionally')

    return {
      onecReceipt,
      onecExtraData,
      onecTotal,
      onecItems,
      onecSelected,

      additionalReceipt,
      additionalTotal,
      additionalExtraData,
      additionalSelected,
      additionalItems
    }
  },
  computed: {
    globalTotal () {
      return this.onecTotal + this.additionalTotal
    },
    globalReceipt () {
      return [
        ...this.onecReceipt,
        ...this.additionalReceipt
      ]
    }
  },
  methods: {
    async onSubmit (e) {
      const { surname, name, patron, companyName, email, phone } = e

      try {
        const res = await this.$api.common.sendClientData({
          surname, name, patronymic: patron, companyName, email, phone
        })
        const applicationId = res.data?.applicationId

        await this.$api.services.sendOnec({
          applicationId,
          service: this.onecSelected,
          additionally: this.additionalSelected,
          price: this.globalTotal
        })

        this.$refs.requestModal.close()
        this.$refs.requestModal.clear()
        this.$refs.modalThank.open()
      } catch (e) {
        console.log(e)
        alert('Произошла ошибка!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.one-c {
  .slogan {
    padding: 40px 0;
    &__text {
      font-size: 18px;
      line-height: 1.6;
      margin-top: 40px;
      max-width: 970px;
    }
  }

  .advantages {
    padding: 40px 0;

    &__list {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      grid-auto-rows: 350px;

      @media screen and (max-width: $media-lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: $media-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $media-esm) {
        grid-template-columns: 1fr;
      }
    }

    &-item {
      @media screen and (max-width: $media-esm) {
        width: 90%;
        margin: 0 auto;
      }
    }
  }

  .calc {
    margin-top: 40px;

    &__subtitle {
      font-weight: 500;
      font-size: 21px;
      margin-top: 40px;
    }
    &-items {
      margin-top: 30px;

      &__item {
        margin-bottom: 10px;
      }
    }
  }

  .additional {
    margin-top: 40px;

    &-checkboxes {
      margin-top: 14px;

      &__item {
        margin-top: 14px;
      }
    }
  }
}
</style>
